<template>
  <b-overlay
      :show="loadingOverlay"
      rounded="sm"
  >

    <div v-if="comments !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="isActive"
                  :options="isActiveOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="ml-2"
              />
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-checkbox
                  id="allCommentsIds"
                  v-model="allCommentsIds"
              />
              <label for="allCommentsIds">انتخاب همه</label>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <b-button
                  variant="primary"
                  @click="submitChanges"
                  :disabled="commentActivations.length === 0"
              >
                <span class="text-nowrap">تغییر وضعیت</span>
              </b-button>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="comments"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: blogCommentId -->
          <template #cell(blogCommentId)="data">
            <b-form-checkbox
                :value="data.item.blogCommentId"
                v-model="commentActivations"
            />
          </template>

          <template #cell(userId)="data">
            <span class="d-block">{{data.item.name}}</span>
            <small class="text-muted">@{{ data.item.userId }}</small>
          </template>

          <!-- Column: createDate -->
          <template #cell(createDate)="data">
                       <span>
                        {{ data.item.createDate.slice(11, 16) }}
                        -
                        {{ createJalaliDate(data.item.createDate.slice(0, 10)) }}
                      </span>
          </template>

          <!-- Column: createDate -->
          <template #cell(isActive)="data">
            <span v-if="data.item.isActive" class="text-success">تایید شده</span>
            <span v-else class="text-danger">تایید نشده</span>
          </template>

          <!-- Column: edit -->
          <template #cell(edit)="data">
            <b-link :to="{ name: 'pages-blog-comments-edit', params: { id: data.item.blogCommentId } }">
              <feather-icon size="20" icon="EditIcon" />
            </b-link>
          </template>

          <!-- Column: delete -->
          <template #cell(delete)="data">
            <b-link class="text-danger" v-b-modal.modal-delete @click="showModal(data.item.blogCommentId)">
              <feather-icon size="20" icon="TrashIcon" />
            </b-link>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteComment(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BModal,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BlogCommentChangeCommentActivationRequest,
  BlogCommentGetAllByBlogIdForAdminRequest,
  BlogCommentGetAllRequest
} from "@/libs/Api/Blog";
import {BlogCommentDeleteRequest} from "@/libs/Api/Blog";

export default {
  name: "BlogCommentsById",
  title:"لیست نظرات بلاگ",
  data(){
    return{
      comments:null,
      loadingOverlay:false,
      blogId:this.$route.params.id,
      currentPage:1,
      isActive:'',
      isActiveOptions:[
        { label: 'همه', value: '' },
        { label: 'تایید شده ها', value: true },
        { label: 'تایید نشده ها', value: false },
      ],
      commentActivations:[],
      totalCounts:null,
      deleteItem:null,
      allCommentsIds:false,
      perPage:10,
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'blogCommentId' ,label :'انتخاب'},
        { key: 'userId' ,label :'شناسه و نام کاربر'},
        { key: 'message' ,label :'متن'},
        { key: 'createDate' ,label :'تاریخ ثبت'},
        { key: 'isActive' ,label :'وضعیت'},
        { key: 'delete',label:'حذف'},
      ],
    }
  },
  async created() {
    await this.getComments(this.isActive);
  },
  methods:{
    async getComments(isActive){
      let _this = this;
      _this.loadingOverlay = true;
      let data = {
        blogId:_this.blogId,
        isActive:isActive,
      }

      let blogCommentGetAllByBlogIdForAdminRequest = new BlogCommentGetAllByBlogIdForAdminRequest(_this);
      blogCommentGetAllByBlogIdForAdminRequest.setParams(data);
      await blogCommentGetAllByBlogIdForAdminRequest.fetch(function (content){
        _this.comments = content
        _this.loadingOverlay = false;
      },function (error){
        _this.loadingOverlay = false;
        console.log(error);
      })
    },
    async submitChanges(){
      let _this = this;
      _this.loadingOverlay = true;

      let blogCommentChangeCommentActivationRequest = new BlogCommentChangeCommentActivationRequest(_this);
      blogCommentChangeCommentActivationRequest.setParams(_this.commentActivations);
      await blogCommentChangeCommentActivationRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات انجام شد.`,
          },
        })
        _this.getComments(_this.isActive);
        _this.allCommentsIds = false;
        _this.commentActivations = [];
        _this.loadingOverlay = false;
      },function (error){
        _this.loadingOverlay = false;
        _this.getComments(_this.isActive);
        _this.allCommentsIds = false;
        _this.commentActivations = [];
        console.log(error)
      })
    },
    async deleteComment(param){
      let _this = this;
      _this.loadingOverlay = true;

      let blogCommentDeleteRequest = new BlogCommentDeleteRequest(_this);
      blogCommentDeleteRequest.setId(param);
      await blogCommentDeleteRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        _this.getComments(_this.isActive);
        _this.loadingOverlay = false;
      },function (error){
        _this.loadingOverlay = false;
        console.log(error)
      })
    },
    setAllComments(){
      console.log("here")
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    isActive:function (nv,ov){
      this.getComments(nv);
    },
    allCommentsIds:function (nv,ov){
      let _this = this;
      if (nv === true){
        _this.comments.forEach(el => {
          _this.commentActivations.push(el.blogCommentId);
        })
      }else {
        _this.commentActivations = [];
      }
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormCheckbox,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
